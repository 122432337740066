import * as React from 'react';

// import Layout from "../components/layout"
import SEO from '../components/seo';
import Wrapper from '../components/Wrapper';

const NotFoundPage = () => (
  <Wrapper>
    <SEO title="404: Not found" />
    <h1>Page Lost to the Abyss</h1>
    <p>
      This isn't here. Where is it? Perhaps nowhere. Let's{' '}
      <a href="/">start from the beginning, shall we?</a>
    </p>
  </Wrapper>
);

export default NotFoundPage;
